import './App.css';
import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup from "./components/login/Signup";
import {Routes, Route} from "react-router-dom";
// import Login component
import Login from "./components/login/Login";
// import Dashboard from "./components/login/Dashboard";
import Edit from "./components/login/Edit";
import StudentDashboard from "./components/login/StudentDashboard";
import Subjects from "./components/subjects/Subjects";
import SubChapter from "./components/subjects/SubChapter";
import MyProfile from "./components/login/MyProfile";
import PrivacyPolicy from "./components/login/PrivacyPolicy";
import BankDetails from './components/login/BankDetails';
import PracticeTest from "./components/test/PracticeTest";
import MockTest from "./components/test/MockTest";
import Standard from "./components/test/Standard";
import MockExamList from "./components/test/MockExamList";
import FinalTest from "./components/test/FinalTest";
import FinalTestResult from "./components/test/FinalTestResult";
import ViewResult from "./components/test/ViewResult";
import ViewReport from "./components/test/viewReport";
import axios from "axios";
function App() {
    // const navigate = useNavigate();
    // preventReload()
    axios.defaults.baseURL = `${window.location.hostname === 'localhost' ? 'http://localhost:3001' : 'https://testportalapi.sindhyaacademy.com'}`;
    return (
    <div className="App">
        <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            {/*<Route path="dashboard" element={<Dashboard />} />*/}
            <Route path="studentDashboard" element={<StudentDashboard />} />
            <Route path="editStudentDetail" element={<Edit />} />
            <Route path="subjects" element={<Subjects />} />
            <Route path="subChapter" element={<SubChapter />} />
            <Route path="myProfile" element={<MyProfile />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="bank-details" element={<BankDetails />} />
            <Route path="practiceTest" element={<PracticeTest />} />
            <Route path="finalTestQuestions" element={<FinalTest />} />
            <Route path="finalTestResult" element={<FinalTestResult />} />
            <Route path="viewResult" element={<ViewResult />} />
            <Route path="viewReport" element={<ViewReport />} />
            <Route path="mockTest" element={<MockTest />} />
            <Route path="standard" element={<Standard />} />
            <Route path="mockExamList" element={<MockExamList />} />
            <Route path="/" element={<Login />} />
        </Routes>
    </div>
  );

    // prevent refresh page not completed development
    // function preventReload() {
    //     const onConfirmRefresh = function (event) {
    //         navigate('/login')
    //         event.preventDefault();
    //         event.returnValue = '';
    //         return false
    //         // return event.returnValue = "Are you sure you want to leave the page?";
    //     }
    //
    //     window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });
    // }
}


export default App;
