import React from "react";
import axios from "axios";
import Student from './../../images/student.png';
import logo from './../../images/sindhya_logo.svg';
import styles from '../css/login.css';
import { Link, useNavigate } from "react-router-dom";

function getLinearGradientBackgroundImage(startColor, endColor) {
    const backgroundImage = `linear-gradient(to bottom, ${startColor}, ${endColor})`;
    return {
        backgroundImage
    };
}
function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({
        'email': '',
        'password': ''
    })
    const [studentData, setStudentData] = React.useState(false)
    const loginStudent = () => {
        axios.post(`/studentDashboard`, {
            email: formData.email,
            password: formData.password,
        }).then((response) => {
            const result = response.data.length === 1 ? true :false;
            setStudentData(result)
            response.data.length ? navigate('/studentDashboard',{
                state : {
                    email : formData.email,
                    studentId : response.data[0].student_id,
                    studentData : response.data[0]
                }
            }) : alert('Invalid Credential'); return false;
        })
    }
    const linearGradientStyle = getLinearGradientBackgroundImage('#0ab9cf' , '#8b3dff');

    return(

        <section className="vh-100" style={linearGradientStyle}>

            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col col-xl-10">
                        <div className="card" style={{borderRadius: "1rem"}}>
                            <img
                                src={logo}
                                alt="login form" className="img3"/>

                            <div className="row g-0">
                                <div className="col-md-6 col-lg-5 d-none d-md-block">
                                    <img
                                        src={Student}
                                        alt="login form" className="img-fluid" style={{borderRadius: "1rem 0 0 1rem"}}/>
                                </div>
                                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                    <div className="card-body p-4 p-lg-5 text-black">
                                        <form>
                                            <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>Sign into
                                                your account</h5>
                                            <div className="form-outline mb-4">
                                                <input value={formData.email} onChange={handleChange} name="email" type="email" id="form2Example17" placeholder={"Email Address"}
                                                       className="form-control form-control-lg"/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input value={formData.password} onChange={handleChange} name="password" type="password" id="form2Example27" placeholder={"Password"}
                                                       className="form-control form-control-lg"/>
                                            </div>

                                            <div className="pt-1 mb-4">
                                                <button className="btn btn-dark btn-lg btn-block" onClick={loginStudent} type="button">Login
                                                </button>
                                            </div>
                                            <a className="small text-muted" href="#!">Forgot password?</a>
                                            <p className="mb-5 pb-lg-2" style={{color: "#393f81"}}>Don't have an
                                                account? <Link to="/signup"
                                                            style={{color: "#393f81"}}>Register here</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    function handleChange(event) {
        const {name, value} = event.target
        setFormData(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
}

export default Login;
