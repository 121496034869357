import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/StudentDashboard.css';
import './../css/scorecard.css';
import {Link, useLocation} from 'react-router-dom'
import Nav from "../login/Nav";
import topper_square from './../../images/topper_square.png';
import PreventGoingBack from "./PreventGoingBack";

function FinalTestResult() {
    useEffect(() => {
        PreventGoingBack()
    }, [])
    const location = useLocation()
    const stateData = location.state
    // console.log(stateData)
    const resultData = stateData.result[0]

    return(
        <div className="fixed-sn cyan-skin biology">
            {<Nav email={stateData.email}/>}
            <main style={{marginTop: "58px"}}>
                <div className="container">
                    <section className="mb-4">

                            <div className="card-header py-3">
                                <h5 className="mb-0 ">
                                    <p className="h3 mb-0 mt-1 text-capitalize ng-binding">Good Try, {stateData?.studentData?.student_name}</p><p
                                    className="mb-1 keepitup">Keep Practicing, Keep
                                    Improving.</p>
                                </h5>
                            </div>

                    </section>
                    <section>
                        <div className="container d-flex justify-content-center align-items-center">

                            <div className="card card-design">
                                <div className="user text-center">

                                    <div className="profile">

                                        <img src={topper_square} className="rounded-circle"
                                             width="80"/>
                                    </div>
                                </div>

                                <div className="mt-5 text-center">

                                    <strong>{resultData.examName}</strong>
                                    <span className=" d-block mb-2 totalscore">Score - {resultData.total ? resultData.total : 0}/180</span>
                                    <div className="d-flex justify-content-between align-items-center mt-4 px-4">

                                        <div className="stats markgained">
                                            <h6 className="mb-2 ">Correct</h6>
                                            <span className="">{resultData.correctAnswer > 0 ? resultData.correctAnswer : 0}</span>
                                        </div>

                                        <div className="stats marklost">
                                            <h6 className="mb-2 ">Incorrect</h6>
                                            <span className="marklost">{resultData.wrongAnswer > 0 ? resultData.wrongAnswer : 0}</span>
                                        </div>

                                        <div className="stats markunattempted">
                                            <h6 className="mb-2 ">Unanswered</h6>
                                            <span className="">{resultData.unAttemptedAnswer > 0 ? resultData.unAttemptedAnswer : 0}</span>
                                        </div>

                                    </div>
                                <Link
                                        to={"/viewResult"} state={{ examName: resultData.examName, email: stateData.email, examId: resultData.examId, result: resultData, countOfQuestns: resultData.countOfQuestns, studentId: stateData.studentId, studentData: stateData.studentData }} >
                                    <button className=" mt-4 mb-4 btn btn-primary btn-sm follow">View Solution</button>
                                </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default FinalTestResult;

