import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import Nav from "../login/Nav";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../login/Breadcrumbs";
import { Popup } from "../popup/Popup";

function Subjects() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const subChapterId = params.get("sid");
  const [chapters, setChapters] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const state = location.state;

  const getChapterData = async () => {
    const url = `/chapterData`;
    axios
      .get(url, {
        params: {
          subChapterId: subChapterId,
        },
      })
      .then((res) => {
        const data = res.data[0];
        setChapters(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getChapterData();
  }, [subChapterId]);

  function subChapterList() {
    if (chapters.data) {
      return (
        <div className="chapter-cover">
          {chapters.data.map((chaptersData) => {
            const primeUser = state?.studentData?.prime_user
              ? true
              : chaptersData.cnumber === 1;
            const class_name =
              parseInt(subChapterId) === 1
                ? "bio"
                : parseInt(subChapterId) === 2
                ? "che"
                : "phy";
            var cno =
              chaptersData.cnumber < 10
                ? `${"0" + chaptersData.cnumber}`
                : chaptersData.cnumber;
            return (
              <div
                onClick={() => setOpen(true)}
                key={chaptersData.cnumber}
                className="chapter"
              >
                <Link
                  style={{ pointerEvents: primeUser ? "auto" : "none" }}
                  to={
                    primeUser
                      ? `/subChapter?cid=${chaptersData.chapter_id}`
                      : `#`
                  }
                  state={{
                    chapterName: chapterName,
                    subChapterId: subChapterId,
                    email: state.email,
                    studentId: state.studentId,
                    studentData: state.studentData,
                  }}
                  className={`chapter-item ${class_name}`}
                >
                  <span
                    style={{ color: chapters.color }}
                    className="chapter-no"
                  >
                    {cno}
                  </span>
                  <div className="chapter-info">
                    <h5 className="mr-5">{chaptersData.cname}</h5>
                    <div className="text-secondary">
                      <small className="me-3">
                        Questions : {chaptersData.count_questions}
                      </small>
                      {/*<small>Duration : {subChaptersData.newData.duration}</small>*/}
                    </div>

                    <span>
                      {!primeUser && (
                        <i
                          className="fa fa-lock lock_Style"
                          aria-hidden="true"
                        ></i>
                      )}
                    </span>
                    <span className="goto-arrow text-secondary">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
          {open ? <Popup closePopup={() => setOpen(false)} /> : null}
        </div>
      );
    }
  }

  const chapterName = chapters?.key;
  return (
    <div className="fixed-sn cyan-skin biology">
      {
        <Nav
          chapterId={subChapterId}
          chapterName={chapterName}
          email={state.email}
        />
      }

      <div className="content-wrapper">
        <div className="container">
          {
            <Breadcrumbs
              chapterId={subChapterId}
              chapterName={chapterName}
              email={state.email}
            />
          }
          <main>
            <div>
              <section className="mb-4">
                <div className="subj-header py-3">
                  <div
                    style={{ color: chapters.color }}
                    className="subj-nav-icon"
                    dangerouslySetInnerHTML={{ __html: chapters.icon }}
                  />
                  <h2 className="mb-0 sub-name">
                    <strong>{chapterName}</strong>
                  </h2>
                </div>
              </section>
              <section>
                <div className="row">{subChapterList()}</div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Subjects;
