import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/StudentDashboard.css';
import {Link, useLocation} from 'react-router-dom'
import axios from "axios";
import ReactPaginate from "react-paginate";
import Nav from "../login/Nav";
import Breadcrumbs from "../login/Breadcrumbs";

function ViewReport() {
    const location = useLocation()
    const stateData = location.state
// console.log(stateData)
    const studentId = stateData.studentId
    const [examNo, setExamNo] = useState(1)
    const [examDetails, setExamDetails] = useState(1)
    const [pageCount, setPageCount] = useState([])

    let finalResult = [];
    const countOfQuestns = stateData.countOfQuestions
    const pageLimit = 10
    const getExamReport = async (data) => {
        let currentPage = data ? data.selected + 1 : 1
        setExamNo(currentPage)
        const url = `/viewReport`;
        axios.get(url, {
            params: {
                studentId: studentId
            },
        }).then(res => {
            const result = res.data
            setExamDetails(result)
            // setPageCount(Math.ceil(result.count/pageLimit))
        }).catch(err => {
            console.log(err);
        })
    };

    useEffect(() => {
        getExamReport()
    }, []);

    function examReportList() {
        if(examDetails.length) {
            return examDetails.map((examDetailsData, index) =>
                <tr key={examDetailsData.newData.answer_id}>
                    <th scope="row">{index + 1}</th>
                    <td>{examDetailsData.newData.exam_name}</td>
                    <td>{examDetailsData.newData.exam_pmark * examDetailsData.newData.totalQuestions}</td>
                    <td>{examDetailsData.newData.total}</td>
                    <td>{examDetailsData.newData.exam_duration}</td>
                    <td>
                        <Link
                            to={"/viewResult"} state={{ examName: examDetailsData.newData.exam_name, email: stateData.email, examId: examDetailsData.newData.exam_id, result: {attempt : JSON.parse(examDetailsData.newData.answers)}, countOfQuestns: examDetailsData.newData.totalQuestions, studentId: studentId }} >
                            <button className="btn btn-outline-success my-2 my-sm-0 btn-sm" type="button">View Result</button>
                        </Link>
                    </td>
                </tr>
            )
        }
    }

    return(
        <div className="fixed-sn cyan-skin biology">
            {<Nav email={stateData.email} report={"My Exam Reports"}/>}
            <div className="content-wrapper">
                <div className="container">
                    {<Breadcrumbs email={stateData.email} report={"My Exam Reports"}/>}
                    <main >

                            <section className="mb-4">

                                    <div className="get-quote">
                                        <div className="row py-3">

                                                <h5 className="mb-0 ">
                                                    <strong className="p-2">My Exam Reports</strong>
                                                </h5>

                                        </div>
                                    </div>

                            </section>
                            <section className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Exam Name</th>
                                        <th scope="col">Max Mark</th>
                                        <th scope="col">Mark Scored</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Detailed Report</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {examReportList()}
                                    </tbody>
                                </table>
                            </section>
                            { /* <ReactPaginate
                            pageCount={parseInt(pageCount)}
                            previousLabel={'prev'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={2}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                            onPageChange={getQuestions}
                        /> */}

                    </main>
                </div>
            </div>
        </div>
    )
}

export default ViewReport;

