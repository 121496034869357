import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/StudentDashboard.css';
import axios from "axios";
import {Link, useLocation} from "react-router-dom";

function Breadcrumbs(props) {
    const location = useLocation()
    // console.log(location)
    const emailState = location.state.email
    const email = (typeof emailState != 'object')  ? emailState
        : emailState ? emailState
            : props.email;
    const [studentId, setStudentId] = useState()
    const [studentData, setStudentData] = useState({})
    const getSubjectsData = async () => {
        const url = `/subjectsData`;
        axios.get(url, {
            params: {
                email: email,
            },
        }).then(res => {
            const data = res.data;
            setStudentId(data[0].studentId)
            setStudentData(data[0].studentData)
        }).catch(err => {
            console.log(err);
        })
    };
    useEffect(() => {
        getSubjectsData();
    }, []);

    return(
        <ol className="breadcrumb" id="custom-breadcrumb">
            <li>
                <Link
                    to={"/studentDashboard"} state={{ email: email, studentId: studentId, studentData: studentData }}
                    className="nav-item nav-link">
                    <i className="fa fa-home fa-fw me-1"></i
                    ><span>Home {(props.chapterName || props.test || props.report) && <span className="fa fa-angle-right ms-2"></span>}</span>
                </Link>
            </li>
            {props.chapterName &&
                <li>
                    <Link
                        to={"/subjects?sid=" + props.chapterId} state={{ email: email, studentId: studentId, studentData: studentData }}
                        className="nav-item nav-link">
                        <i className="fas fa-chart-area fa-fw me-2"></i
                        ><span>{props.chapterName} {props.subChapter && <span className="fa fa-angle-right ms-2"></span>}</span>
                    </Link>
                </li>
            }
            {props.subChapter &&
                <li>
                    <span className="nav-item nav-link breadcrumb-item active">
                        <i className="fas fa-chart-area fa-fw me-2"></i>
                        <span>{props.subChapter}</span>
                    </span>
                </li>
            }
            {props.test &&
                <li>
                    <Link
                        to={"/mockTest"} state={{ email: email, studentId: studentId, studentData: studentData }}
                        className="nav-item nav-link">
                        <i className="fas fa-chart-area fa-fw me-2"></i
                        ><span>{props.test} {props.subjectName && <span className="fa fa-angle-right ms-2"></span>}</span>
                    </Link>
                </li>
            }
            {props.subjectName &&
                <li>
                    <Link
                        to={"/standard"} state={{ test: props.test, examFolderId: props.examFolderId, subjectName: props.subjectName, email: email, studentId: studentId, studentData: studentData }}
                        className="nav-item nav-link">
                        <i className="fas fa-chart-area fa-fw me-2"></i
                        ><span>{props.subjectName} {(props.class || props.mockExam) && <span className="fa fa-angle-right ms-2"></span>}</span>
                    </Link>
                </li>
            }
            {props.class &&
                <li>
                    <span className="nav-item nav-link breadcrumb-item active">
                        <i className="fas fa-chart-area fa-fw me-2"></i>
                        <span>{props.class}</span>
                    </span>
                </li>
            }
            {props.report &&
                <li>
                    <Link
                        to={"/viewReport"} state={{ email: email, studentId: studentId, studentData: studentData}}
                        className="nav-item nav-link">
                        <i className="fas fa-chart-area fa-fw me-2"></i
                        ><span>{props.report} {props.result && <span className="fa fa-angle-right ms-2"></span>}</span>
                    </Link>
                </li>
            }
            {props.result &&
                <li>
                    <span className="nav-item nav-link breadcrumb-item active">
                        <i className="fas fa-chart-area fa-fw me-2"></i>
                        <span>{props.result}</span>
                    </span>
                </li>
            }
            {props.mockExam &&
                <li>
                    <span className="nav-item nav-link breadcrumb-item active">
                        <i className="fas fa-chart-area fa-fw me-2"></i>
                        <span>{props.mockExam}</span>
                    </span>
                </li>
            }
        </ol>
    )
}

export default Breadcrumbs;

