import React, { useRef, useEffect, useState } from "react";
import "./Popup.css";
import "./../login/Otp.css"
export const PopupOtp = ({ closePopup, lastDigit, sendDataToParent, otpCount, generateOtp }) => {
    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otp, setOtp] = useState("");
  const otpFieldsRef = useRef([]);

  const handleInput = (index, value) => {
    if (value.length > 1) {
      return;
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtp(newOtpValues.join(""))
    setOtpValues(newOtpValues);
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    if (otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  }


  function OTPInput() {
    const inputs = document.querySelectorAll("#otp > *[id]");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keydown", function (event) {
        if (event.key === "Backspace") {
          inputs[i].value = "";
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== "") {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
  }
  return (
    <div className="popup-container">
      <div className="popup-body">
        <a
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closePopup}
        >
          <span aria-hidden="true" className="text-danger">
            &times;
          </span>
        </a>
        <div className="container">
          <div>
            <div className="card p-2 text-center">
              <h6>
                Please enter the one time password to verify your account{" "}
              </h6>
              <div>
                <span>A code has been sent to</span>
                <small>*******{lastDigit}</small>
              </div>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2"
              >
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="m-2 text-center form-control rounded"
                    value={value}
                    onChange={(e) => handleInput(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        handleBackspace(index);
                      }
                    }}
                    ref={(ref) => {
                      otpFieldsRef.current[index] = ref;
                    }}
                  />
                ))}
              </div>
              <div className="mt-2">
                <button onClick={() => {sendDataToParent(otp)}} type="button" className="btn btn-danger px-4 validate">
                  Validate
                </button>
                <div className="content mt-3 d-flex justify-content-center align-items-center">
                <span>Didn't get the code</span>
                <a href="#!" onClick={() => {generateOtp()}} className="text-decoration-none ms-3">
                  {`Resend(${otpCount}/3)`}
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
