import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Nav from "../login/Nav";
import Breadcrumbs from "../login/Breadcrumbs";
import { MathJax, MathJaxContext } from "better-react-mathjax";

function ViewResult() {
  const location = useLocation();
  const stateData = location.state;
  const [pageCount, setPageCount] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const countOfQuestns = stateData.countOfQuestns;
  const examId = stateData.examId;

  const pageLimit = 10;
  const viewResults = async (data) => {
    document.getElementById("view_Result").scrollIntoView();
    let currentPage = data ? data.selected + 1 : 1;
    currentPage >= 2 && setPage(10 * (currentPage - 1));
    currentPage == 1 && setPage(1);
    const url = `/viewResult?_page=${currentPage}&_limit=${pageLimit}`;
    axios
      .get(url, {
        params: {
          examId: examId,
          selected: stateData.result.attempt,
        },
      })
      .then((res) => {
        const result = res.data[0];
        setQuestions(result.data);
        setPageCount(Math.ceil(countOfQuestns / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    viewResults();
  }, []);
  function subChapterExamList() {
    if (questions.length) {
      return questions.map((questionsData, qno) => (
        <div
          key={questionsData.newData.question_id}
          className="mt-md-4 my-1 view_answer_box"
        >
          <div className="question ml-sm-1 pl-sm-1 pt-2">
            <div className="py-2 question_view_result">
              <MathJaxContext>
                <MathJax className="formula" inline dynamic>
                  {page >= 2 ? qno + 1 + page : qno + page} )
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionsData.newData.question,
                    }}
                  />
                </MathJax>
              </MathJaxContext>
            </div>
            <div className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="options">
              <p className="options">
                <MathJaxContext>
                  <MathJax className="formula" inline dynamic>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionsData.newData.opt_1,
                      }}
                    />
                  </MathJax>
                </MathJaxContext>
                <input
                  id="resultRadioDefault1"
                  defaultChecked={questionsData.newData.selectedAnswer === 1}
                  type="radio"
                  value={1}
                  name={"selectedAnswer" + questionsData.newData.question_id}
                />
                <span className="checkmark"></span>
              </p>
              <p className="options">
                <MathJaxContext>
                  <MathJax className="formula" inline dynamic>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionsData.newData.opt_2,
                      }}
                    />
                  </MathJax>
                </MathJaxContext>
                <input
                  id="resultRadioDefault2"
                  defaultChecked={questionsData.newData.selectedAnswer === 2}
                  type="radio"
                  value={2}
                  name={"selectedAnswer" + questionsData.newData.question_id}
                />
                <span className="checkmark"></span>
              </p>
              <p className="options">
                <MathJaxContext>
                  <MathJax className="formula" inline dynamic>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionsData.newData.opt_3,
                      }}
                    />
                  </MathJax>
                </MathJaxContext>
                <input
                  id="resultRadioDefault3"
                  defaultChecked={questionsData.newData.selectedAnswer === 3}
                  type="radio"
                  value={3}
                  name={"selectedAnswer" + questionsData.newData.question_id}
                />
                <span className="checkmark"></span>
              </p>
              <p className="options">
                <MathJaxContext>
                  <MathJax className="formula" inline dynamic>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionsData.newData.opt_4,
                      }}
                    />
                  </MathJax>
                </MathJaxContext>
                <input
                  id="resultRadioDefault4"
                  defaultChecked={questionsData.newData.selectedAnswer === 4}
                  type="radio"
                  value={4}
                  name={"selectedAnswer" + questionsData.newData.question_id}
                />
                <span className="checkmark"></span>
              </p>
            </div>
          </div>
          <div className="ml-sm-1 pl-sm-1 pt-2">
            {questionsData.newData.selectedAnswer == "" ? (
              <p className="text-warning">You Havn't Attened The Question</p>
            ) : parseInt(questionsData.newData.selectedAnswer) ===
              parseInt(questionsData.newData.answer) ? (
              <p className="text-info">You Are Right</p>
            ) : (
              <p className="text-danger">You Are Wrong</p>
            )}
            <div className="solution-text">
              <div className="test_solution_view">Solution :</div>
              <p className="text-success px-2">
                <MathJaxContext>
                  <MathJax className="formula" inline dynamic>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionsData.newData.solution,
                      }}
                    />
                  </MathJax>
                </MathJaxContext>
              </p>
            </div>
          </div>
        </div>
      ));
    }
  }

  return (
    <div className="fixed-sn cyan-skin biology">
      {
        <Nav
          email={stateData.email}
          report={"My Exam Reports"}
          result={"My Exam Result"}
        />
      }
      <div id="view_Result" className="content-wrapper">
        <div className="container">
          {
            <Breadcrumbs
              email={stateData.email}
              report={"My Exam Reports"}
              result={"My Exam Result"}
            />
          }
          <main className="view_Result">
            <div className="pt-4">
              <section className="mb-1">
                <div className="card-header  exam_name py-3">
                  <h5 className="mb-0 exam_name2">
                    <strong>{stateData.examName}</strong>
                  </h5>
                </div>
              </section>
              <section>
                <div className="row">{subChapterExamList()}</div>
              </section>
              <ReactPaginate
                pageCount={parseInt(pageCount)}
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={viewResults}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ViewResult;
