import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../css/Signup.css";
import { PopupOtp } from "../popup/Otp";

function Signup() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = React.useState({
    student_name: "",
    student_email: "",
    student_password: "",
    student_same_password: "",
    student_phone_number: "",
    student_batch: "",
    student_class: "",
    student_school: "",
    student_country: "",
    student_state: "",
    student_city: "",
  });

  const classList = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];
  const [batchDetails, setBatchDetails] = useState([]);
  const [countryDetails, setCountryDetails] = useState([]);
  const [stateDetails, setStateDetails] = useState([]);
  const [cityDetails, setCityDetails] = useState([]);
  const [selectedCityDetails, setSelectedCityDetails] = useState([]);
  const [otp, setOtp] = useState("");
  const [wrongOtpCount, setWrongOtpCount] = useState(0);
  const [otpCount, setOtpCount] = useState(0);

  useEffect(() => {
    const fetchBatchData = () => {
      axios.get(`/batchDetails`).then((response) => {
        setBatchDetails(response.data);
      });
    };
    const fetchCountryData = () => {
      axios.get(`/countryDetails`).then((response) => {
        setCountryDetails(response.data[0]);
      });
    };
    const fetchStateData = () => {
      axios.get(`/stateDetails`).then((response) => {
        setStateDetails(response.data);
      });
    };
    const fetchCityData = () => {
      axios.get(`/cityDetails`).then((response) => {
        setCityDetails(response.data);
      });
    };
    fetchBatchData();
    fetchCountryData();
    fetchStateData();
    fetchCityData();
  }, []);

  function createStudentData() {
    axios
      .post(`/createStudent`, {
        name: formData.student_name,
        email: formData.student_email,
        password: formData.student_password,
        phone_number: formData.student_phone_number,
        batch: formData.student_batch,
        class: formData.student_class,
        school: formData.student_school,
        country: formData.student_country,
        state: formData.student_state,
        city: formData.student_city,
      })
      .then((response) => {
        if (response.data[0]) {
          navigate("/login");
          alert("Account Created. Login Here");
        } else {
          alert("Email Already Exist");
          return false;
        }
      });
  }

  const checkValidation = async (student_email, student_phone_number) => {
    let data = await getStudentExist(student_email, student_phone_number);
    if (data?.student_email == formData.student_email) {
      alert("Email Already Exist");
    } else if (data?.student_phone == formData.student_phone_number) {
      alert("Phone Number Already Exist");
    } else {
      // generateOtp();
      createStudentData();
    }
  };

  async function getStudentExist(email, phoneNo) {
    return new Promise((resolve, reject) => {
      const url = `/studentExist`;
      axios
        .get(url, {
          params: {
            email: email,
            student_phone: phoneNo,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const generateOtp = () => {
    const otp = Math.floor(100000 + Math.random() * 900000);
    const data = {
      phone: formData.student_phone_number,
      otp: otp,
    };
    let resendCountLimit = otpCount + 1;
    if (resendCountLimit > 3) {
      setOpen(false);
      navigate("/login");
      alert("Exceeded the limit. Please signup again");
    } else {
      setOpen(true);
      axios.post(`/sendOtp`, data).then((response) => {
        console.log(response);
      });
      setOtp(otp);
      setOtpCount(otpCount + 1);
    }
  };

  const createStudent = () => {
    for (const el of document
      .getElementById("form")
      .querySelectorAll("[required]")) {
      if (!el.reportValidity()) {
        return;
      }
    }

    if (formData.student_password !== formData.student_same_password) {
      alert("Password doesn't match");
      return false;
    }

    if (formData.student_batch == "") {
      alert("Please select batch");
      return false;
    }
    if (formData.student_class == "") {
      alert("Please select class");
      return false;
    }
    if (formData.student_country == "") {
      alert("Please select state");
      return false;
    }
    if (formData.student_state == "") {
      alert("Please select state");
      return false;
    }
    if (formData.student_city == "") {
      alert("Please select city");
      return false;
    }
    checkValidation(formData.student_email, formData.student_phone_number);
  };
  const classListDetails = () => {
    return (
      <>
        {classList.map((ele, key) => {
          return (
            <option key={key} value={ele}>
              {ele}
            </option>
          );
        })}
      </>
    );
  };
  const batchList = () => {
    if (batchDetails) {
      return (
        <>
          {batchDetails.map(({ batch_id, batch_name }) => {
            return (
              <option key={batch_id} value={batch_id}>
                {batch_name}
              </option>
            );
          })}
        </>
      );
    }
  };

  const countryList = () => {
    if (countryDetails) {
      return (
        <>
          {countryDetails.map(({ country_id, country_name }) => {
            return (
              <option key={country_id} value={country_id}>
                {country_name}
              </option>
            );
          })}
        </>
      );
    }
  };

  const stateList = () => {
    if (stateDetails) {
      return (
        <>
          {stateDetails.map(({ state_id, state_name }) => {
            return (
              <option key={state_id} value={state_id}>
                {state_name}
              </option>
            );
          })}
        </>
      );
    }
  };

  const cityList = () => {
    if (selectedCityDetails) {
      return (
        <>
          {selectedCityDetails.map(({ city_id, city_name }) => {
            return (
              <option key={city_id} value={city_id}>
                {city_name}
              </option>
            );
          })}
        </>
      );
    }
  };
  const sendDataToParent = (studentOtp) => {
    if (studentOtp == otp) {
      createStudentData();
    } else {
      const checkCount = wrongOtpCount + 1;
      setWrongOtpCount(checkCount);
      if (checkCount > 2) {
        setOpen(false);
        navigate("/login");
        alert("Exceeded the attempt. Please signup again");
      } else {
        alert("Invalid Otp. Please try again");
      }
    }
  };

  const lastDigit = formData?.student_phone_number.substring(
    formData.student_phone_number.length - 4
  );

  return (
    <>
      <section className="vh-100 gradient-custom">
        <div className="container h-100" style={{ paddingTop: "10px" }}>
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-9 col-xl-7">
              <div
                className="card shadow-2-strong card-registration"
                style={{ borderRadius: "15px" }}
              >
                <div className="card-body p-4 p-md-4">
                  <h3 className="mb-4 pb-2 pb-md-0">Sign up</h3>
                  <form id="form">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            required
                            value={formData.student_name}
                            name="student_name"
                            onChange={handleChange}
                            type="text"
                            placeholder={"Name"}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            required
                            value={formData.student_email}
                            name="student_email"
                            onChange={handleChange}
                            type="email"
                            placeholder={"Email"}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            required
                            name="student_password"
                            value={formData.student_password}
                            onChange={handleChange}
                            type="password"
                            placeholder={"Password"}
                            // pattern="(?=.*\d).{8,20}"
                            title="Must contain at least 8 or more characters"
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            required
                            name="student_same_password"
                            value={formData.student_same_password}
                            onChange={handleChange}
                            type="password"
                            placeholder={"Re-type password"}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-outline mb-4">
                        <input
                          required
                          value={formData.student_phone_number}
                          type="text"
                          pattern="[6789][0-9]{9}"
                          title="Please enter valid phone number"
                          onChange={handleChange}
                          name="student_phone_number"
                          placeholder={"Phone Number"}
                          className="form-control form-control-lg"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <select
                          required
                          value={formData.student_batch}
                          onChange={handleChange}
                          name="student_batch"
                          className="form-select form-control form-control-lg"
                        >
                          <option value="#">Batch</option>
                          {batchList()}
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <select
                          required
                          value={formData.student_class}
                          onChange={handleChange}
                          name="student_class"
                          className="form-select form-control form-control-lg"
                        >
                          <option value="#">Class</option>
                          {classListDetails()}
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          required
                          value={formData.student_school}
                          onChange={handleChange}
                          name="student_school"
                          type="text"
                          id="school"
                          placeholder={"School Name"}
                          className="form-control form-control-lg"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <select
                          required
                          value={formData.student_country}
                          onChange={handleChange}
                          name="student_country"
                          className="form-select form-control form-control-lg"
                        >
                          <option value="#">Country</option>
                          <option
                            key={countryDetails.country_id}
                            value={countryDetails.country_id}
                          >
                            {countryDetails.country_name}
                          </option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <select
                          required
                          value={formData.student_state}
                          onChange={handleChange}
                          name="student_state"
                          className="form-select form-control form-control-lg"
                        >
                          <option value="#">State</option>
                          {stateList()}
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <select
                          required
                          value={formData.student_city}
                          onChange={handleChange}
                          name="student_city"
                          className="form-select form-control form-control-lg"
                        >
                          <option value="#">City</option>
                          {cityList()}
                        </select>
                      </div>
                    </div>
                    <div className="pt-1 mb-2">
                      <button
                        className="btn btn-dark btn-lg btn-block"
                        onClick={createStudent}
                        type="button"
                      >
                        Sign Up
                      </button>
                      {open ? (
                        <PopupOtp
                          lastDigit={lastDigit}
                          sendDataToParent={sendDataToParent}
                          closePopup={() => setOpen(false)}
                          otpCount={otpCount}
                          generateOtp={generateOtp}
                        />
                      ) : null}
                    </div>
                    <div className="col-md-6 mb-1">
                      <p style={{ color: "#393f81" }}>
                        Already have an account?{" "}
                        <Link style={{ color: "#393f81" }} to="/">
                          Login
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
  function handleChange(event) {
    var { name, type, value, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    name === "student_state" && cityChange(name, value);
  }
  function cityChange(name, value) {
    const selectedCity = cityDetails.filter((ele) => ele.state_id == value);
    setSelectedCityDetails(selectedCity);
  }
}

export default Signup;
