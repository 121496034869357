import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/StudentDashboard.css";
import Nav from "../login/Nav";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../login/Breadcrumbs";
import { Popup } from "../popup/Popup";

function SubChapter() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const chapterId = params.get("cid");
  const [subChapters, setSubChapters] = useState([]);
  const [paramData, setParamData] = useState({});
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const getSubChapterData = async () => {
    const url = `/subChapterData`;
    axios
      .get(url, {
        params: {
          chapterId: chapterId,
        },
      })
      .then((res) => {
        const data = res.data[0];
        setSubChapters(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSubChapterData();
    setParamData(location.state);
  }, []);

  function subChapterList() {
    if (subChapters.subjectId) {
      const primeUser = paramData?.studentData?.prime_user
        ? true
        : subChapters.chapterNo === 1;
      return (
        <div className="chapter-cover">
          {subChapters.data.map((subChaptersData) => {
            const sub_chapter_id = subChaptersData.newData.sub_chapter_id;
            const sub_chapter_name = subChaptersData.newData.sub_chapter_name;

            return (
              <div
                onClick={() => setOpen(true)}
                key={sub_chapter_id}
                className="sub-chapter"
              >
                <Link
                  to={primeUser ? `/practiceTest` : `#`}
                  state={{
                    subid: sub_chapter_id,
                    email: paramData.email,
                    subChapterName: sub_chapter_name,
                    chapterId: chapterId,
                    chapterName: subChapters.chapterName,
                    subject: paramData.chapterName,
                    subjectId: subChapters.subjectId,
                    studentId: paramData.studentId,
                    studentData: paramData.studentData,
                  }}
                  className={`sub-chapter-item`}
                >
                  {/*<span className="chapter-no">{cno}</span>*/}
                  <div className="chapter-info">
                    <h5 className="mr-5 d-inline-block">{sub_chapter_name}</h5>
                    <div className="text-secondary">
                      <small className="me-3">
                        Questions : {subChaptersData.newData.totalQuestions}
                      </small>
                      {/*<small>Duration : {subChaptersData.newData.duration}</small>*/}
                    </div>
                    <span className="goto-arrow text-secondary">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
          {open ? <Popup closePopup={() => setOpen(false)} /> : null}
        </div>
      );
    }
  }

  return (
    <div className="fixed-sn cyan-skin biology">
      {
        <Nav
          subChapter={subChapters.chapterName}
          chapterId={paramData.subChapterId && paramData.subChapterId}
          chapterName={paramData.chapterName && paramData.chapterName}
          email={paramData.email}
        />
      }
      <div className="content-wrapper">
        <div className="container">
          {
            <Breadcrumbs
              subChapter={subChapters.chapterName}
              chapterId={paramData.subChapterId && paramData.subChapterId}
              chapterName={paramData.chapterName && paramData.chapterName}
              email={paramData.email}
            />
          }
          <main>
            <div>
              <section className="mb-4">
                <div className="card-header py-3">
                  <h5 className="mb-0">
                    <strong>{subChapters.chapterName}</strong>
                  </h5>
                </div>
              </section>
              <section>
                <div className="row">{subChapterList()}</div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default SubChapter;
